import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/homePage/index.vue'),
  },
  {
    path: '',
    component: () => import('@/views/details/index.vue'),
    children: [
      {
        path: 'details/:p_category/:category',
        component: () => import('@/views/details/details.vue'),

      },
      {
        path: 'details-1',
        component: () => import('@/views/details/details-1.vue'),

      },
      {
        path: 'details-2',
        component: () => import('@/views/details/details-2.vue'),

      },
      {
        path: 'details-3',
        component: () => import('@/views/details/details-3.vue'),

      },
      {
        path: 'details-4',
        component: () => import('@/views/details/details-4.vue'),

      },
      {
        path: 'details-5',
        component: () => import('@/views/details/details-5.vue'),
      },
      {
        path: 'details-6',
        component: () => import('@/views/details/details-1 copy.vue'),
      },
      {
        path: 'particulars_gg',
        component: () => import('@/views/details/particulars_gg.vue'),
      },
      {
        path: '404',
        component: () => import('@/views/details/404.vue'),
      },
      {
        path: 'search',
        component: () => import('@/views/details/search.vue'),
      }
    ]
  },
  {
    // path: 'particulars/:categoryId',
    name: 'particulars',
    path: '/particulars/:categoryId',
    component: () => import('@/views/details/particulars.vue'),
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const originpush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return originpush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
