<template>
  <div id="app">
    <!-- 一级路由出口 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  // 宽度: document.body.clientWidth
  // 高度: document.body.clientHeight

  data ()
  { // 可直接挂载到data数据上
    return {
      screenWidth: document.body.clientWidth,
    }
  },
  mounted ()
  {
    this.screenWidth = document.body.clientWidth;
    let html = document.querySelector('html')
    if(window.innerWidth<1440){
        html.style.fontSize = "144px"
      }
    this.screenWidth = window.innerWidth / 10 + "px"
    html.style.fontSize = this.screenWidth
    window.onresize = () =>
    {
      this.screenWidth = window.innerWidth / 10 + "px"
      html.style.fontSize = this.screenWidth
      if(window.innerWidth<1440){
        html.style.fontSize = "144px"
      }

    }
  }
}
</script>

<style>
#app {
  min-width: 1440Px;
}
</style>
