import 'babel-polyfill'
import 'classlist-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import 'amfe-flexible'
import '@/assets/styles/base.css'
import '@/assets/styles/cssmenu.css'
import '@/assets/icons'// icon
import './assets/icons/index'
import * as echarts from 'echarts';
import iframeResize from 'iframe-resizer/js/iframeResizer';

Vue.prototype.$echarts = echarts

Vue.prototype.zzUrl = process.env.VUE_APP_URL_ZZ;
Vue.prototype.gwUrl = process.env.VUE_APP_URL_GW;
Vue.prototype.cmsUrl = process.env.VUE_APP_URL_CMS;
Vue.prototype.oldWebUrl = process.env.VUE_APP_URL_OLD;


Vue.use(ElementUI)

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => {
      el.style.visibility = "visible";
      iframeResize(value, el)
    })
  },
  unbind: function (el) {
    el && el.iFrameResizer && el.iFrameResizer.removeListeners()
    //el.iFrameResizer.removeListeners();
  }
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
